import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      delivery_date_start: null,
      delivery_date_end: null,
      perPage: 6,
      sortBy: 'name',
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      fields: [
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'receipt_no', 
          label: 'เลขที่ใบเสร็จ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'delivery_note_date', 
          label: 'วันที่ใบส่งของ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'delivery_note', 
          label: 'เลขที่ใบส่งของ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_no', 
          label: 'เลขที่ใบกำกับภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'code', 
          label: 'รหัสลูกหนี้', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ชื่อลูกหนี้', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'nettotal', 
          label: 'จำนวนเงิน',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        },
        { 
          key: 'cash', 
          label: 'เงินสด', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'transfer', 
          label: 'เงินโอน', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'credit_card', 
          label: 'เช็ค/บัตร', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'disposit', 
          label: 'เงินมัดจำ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'discount', 
          label: 'ส่วนลด', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'expenses', 
          label: 'ค่าใช้จ่ายอื่น ๆ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'fee', 
          label: 'ค่าธรรมเนียม', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'withholding', 
          label: 'ภาษี ณ ที่จ่าย', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_date: new Date(),
          doc_no: 'ARIN-201665156',
          receipt_no: 'RV-201665156',
          delivery_note: 'CR-201665156',
          delivery_note_date: new Date(),
          inv_no: 'INV-201665156',
          code: 'DT-001',
          name: 'กชมน',
          nettotal: 2000,
          cash: 2000,
          transfer: '',
          credit_card: '',
          disposit: '',
          discount: '',
          expenses: '',
          fee: '',
          vat: 3,
          status: '<label class="badge badge-success">จ่ายแล้ว</label>'
        }
      ],
      status: null,
      paidStatus: [
        { text: 'ค้างชำระ', value: 'overdue' },
        { text: 'ชำระบางส่วน', value: 'partial' },
        { text: 'ชำระแล้ว', value: 'paid' }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}